import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

const MaisonMarieTiers = ({ lang, expandTiers }) => {
  const [showTiers, setShowTiers] = useState(true);
  const [hideExpander, setHideExpander] = useState(false);

  const copyStrings = {
    en: {
      heading: 'What’s my MSP tier?',
      tableHeading: 'Benefits',
      tableColHeader1: 'M',
      tableColDesc1: '$0 to $4,999',
      tableColHeader2: 'S',
      tableColDesc2: '$5,000 to $9,999',
      tableColHeader3: 'P',
      tableColDesc3: '$10,000+',
      tableRowDesc1: 'Exclusive styling and behind the scenes content',
      tableRowDesc2: 'Birthday gift',
      tableRowDesc4: 'Access to member-only contests',
      tableRowDesc5: 'Free shipping on all orders',
      tableRowDesc6: 'MSP Anniversary Gift',
      tableRowDesc7: 'VIP invitations to in-store events (Runways, Trunkshows, etc.)',
      tableRowDesc8: 'Access to exclusive colors & styles',
      tableRowDesc9: 'Early online access to sales',
      tableRowDesc10: 'Early online access to new collections',
      tableRowDesc11: 'Access to Atelier & Exclusive Collections on sale',
      tableRowDesc12: 'Free returns',
      tableRowDesc13: 'Free basic alterations',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
    if (expandTiers) {
      setShowTiers(true);
      setHideExpander(true);
    }
  }, []);

  return (
    <div className={!hideExpander ? 'custom-tiers__breakdown' : 'custom-tiers__breakdown -slim'}>
      <div className={!hideExpander ? 'custom-tiers__inner' : 'custom-tiers__inner -slim'}>
        {!hideExpander
        && (
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        )}
        {showTiers
        && (
        <>
          <div className="tiers-description__wrapper">
            <div className="tiers-poems">
              <div className="tiers-poem__item -m">
                <img className="tiers-poem__icon" src="https://assets.heyethos.com/maisonmarie/tier-m.png" alt="M" />
                <div className="tiers-poem__verses">
                  <p>
                    M, a mark of grace,
                    Marie’s vision, a warm embrace.
                    Mesh of dreams, fine threads align,
                    Crafting beauty, line by line.
                    <br />
                    M, the Maison’s gentle heart,
                    <br />
                    Where simple elegance finds its start.
                    <br />
                    Majesty stitched, in every seam,
                    <br />
                    Each piece a passage of a vivid dream.
                    <br />
                    Marie’s name, the soul within,
                    A legacy strong, where stories begin.
                    Mesh and mastery, texture, flow—
                    In the Maison’s hands, beauty grows.
                  </p>
                </div>
              </div>
              <div className="tiers-poem__item -s">
                <img className="tiers-poem__icon" src="https://assets.heyethos.com/maisonmarie/tier-s.png" alt="S" />
                <div className="tiers-poem__verses">
                  <p>
                    S, a curve of style and might,
                    Satin sheen in gentle light.
                    Silk and sequins, rich and rare,
                    Textures woven with refined care.
                    <br />
                    Sponge and scuba, bold and true,
                    Depth and charm in every hue.
                    The Maison’s mark, both strong and free,
                    S where elegance starts to see.
                    <br />
                    S shapes the core of style and grace,
                    A crafted blend, a sleek embrace.
                    With each soft fold and line anew,
                    S in Maison feels bold, yet true.
                  </p>
                </div>
              </div>
              <div className="tiers-poem__item -p">
                <img className="tiers-poem__icon" src="https://assets.heyethos.com/maisonmarie/tier-p.png" alt="P" />
                <div className="tiers-poem__verses">
                  <p>
                    P, poised at Maison’s peak,
                    Patterns rich, textures unique.
                    Ponte strong, both pure and wise,
                    A sculpted form where beauty lies.
                    <br />
                    P for prestige, polished, rare,
                    An artful touch beyond compare.
                    In every fold and cut precise,
                    P marks Maison’s grandest heights.
                    <br />
                    Patterns whisper, both soft and bold,
                    A story woven, bright and gold.
                    Maison’s pinnacle, graced with care,
                    A polished realm, beyond compare.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={!hideExpander ? 'custom-tiers__table' : 'custom-tiers__table -slim'}>
            <table>
              <tr>
                <th>{copyStrings[lang].tableHeading}</th>
                <th>
                  <img className="custom-tiers__header-img" src="https://assets.heyethos.com/maisonmarie/tier-m.png" alt="M" />
                  <span dangerouslySetInnerHTML={{ __html: copyStrings[lang].tableColDesc1 }}></span>
                </th>
                <th>
                  <img className="custom-tiers__header-img" src="https://assets.heyethos.com/maisonmarie/tier-s.png" alt="S" />
                  <span dangerouslySetInnerHTML={{ __html: copyStrings[lang].tableColDesc2 }}></span>
                </th>
                <th>
                  <img className="custom-tiers__header-img" src="https://assets.heyethos.com/maisonmarie/tier-p.png" alt="P" />
                  <span dangerouslySetInnerHTML={{ __html: copyStrings[lang].tableColDesc3 }}></span>
                </th>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc1}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc2}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc4}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc5}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc6}</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc7}</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc8}</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc9}</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc10}</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc11}</td>
                <td></td>
                <td></td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc12}</td>
                <td></td>
                <td></td>
                <td>-</td>
              </tr>
              <tr>
                <td>{copyStrings[lang].tableRowDesc13}</td>
                <td></td>
                <td></td>
                <td>-</td>
              </tr>
            </table>
          </div>
        </>
        )}
      </div>
    </div>
  );
};
export default MaisonMarieTiers;
